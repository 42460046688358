export default [
  {
    icon: 'digital',
    title: 'MARKETING',
    href: '/digital-marketing/',
    list: [
      'Omni Channel Strategy',
      'Persona / Buyer Development',
      'Search Engine Optimization',
      'Social Media Marketing',
      'Prescriptive & Predictive Analytics',
    ]
  },
  {
    icon: 'branding',
    title: 'DESIGN + BRANDING',
    href: '/design-and-branding/',
    list: [
      'Brand Development',
      'Web + App UX/UI',
      'Graphic Design',
      'Logo Development',
      'Video Production',
    ]
  },
  {
    icon: 'web-app',
    title: 'web + app',
    href: '/web-app/',
    list: [
      'Corporate Website',
      'eCommerce',
      'Mobile App Design + Dev',
      'Headless CMS',
      'CRM / ERP Integration',
    ]
  }
];
