import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Img from 'img';
import { navigate } from 'gatsby';
import SwiperBlock from 'shared/swiper-block';

import s from './clients-section.module.scss';

const clients = [
  {
    client: 'intermiami',
    href: '/client/inter-miami-cf/'
  },
  // {
  //   client: 'boomer',
  //   href: ''
  // },
  {
    client: 'flhone',
    href: ''
  },
  {
    client: 'ecs',
    href: ''
  },
  {
    client: 'nofault',
    href: ''
  },
  // {
  //   client: 'laking',
  //   href: ''
  // },
  {
    client: 'connections',
    href: '/client/the-connections-group/'
  },
  {
    client: 'cor',
    href: ''
  },
  {
    client: 'furlough',
    href: ''
  },
  {
    client: 'cannapan',
    href: '/client/cannapan/'
  },
  {
    client: 'shock',
    href: ''
  },
  {
    client: 'aeon',
    href: ''
  }
];

export default function ClientsSection() {
  const params = {
    slidesPerView: 6,
    spaceBetween: 0,
    breakpoints: {
      1199: {
        slidesPerView: 4,
        spaceBetween: 0
      },
      767: {
        slidesPerView: 2,
        spaceBetween: 0
      }
    }
  };
  return (
    <Container className={s.root}>
      <Headline h1 dash className={s.headline} right el="h3">
        ALONG THE WAY
        <span className={s.partial}>, WE’VE HELPED</span>
        <br />
        <span className={s.partial}>SOME REALLY COOL BRANDS</span>
      </Headline>
      <Headline className={s.subtitle} h3 el="h4" center>
        We’ve helped some really
        <br />
        cool brands
      </Headline>
      <SwiperBlock params={params} className={s.swiperBlock}>
        {clients.map(({ client, href }) => (
          <Img
            src={`logo-${client}`}
            key={`logo-${client}`}
            className={s.clientImage}
            href={href}
          />
        ))}
      </SwiperBlock>
      <div className={s.imageSection}>
        <div className={s.imgWrapper}>
          <Img className={s.imgBackground} src="cxn-beach2x" />
        </div>
        <img
          className={s.contentImage}
          src="/svg/cxn-story-together.svg"
          alt="lets tell your story together"
        />
      </div>
    </Container>
  );
}
