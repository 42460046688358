import React from 'react';
import Container from 'container';
import Headline from 'headline';
import CollageGrid from 'shared/collage-grid';
import Button from 'button';

import s from './featured-work.module.scss';

export default function FeaturedWork({ featuredList }) {
  const featured = featuredList.map(({ featured, slug }) => ({
    title: featured.title,
    subtitle: featured.services_provided,
    small_image: featured.small_image,
    large_image: featured.large_image,
    href: slug,
    showcase: featured.showcase,
  }))
  .filter(({ showcase }) => showcase);
  return (
    <Container className={s.root}>
      <Headline h1 className={s.title}>
        FEATURED WORK
      </Headline>
      <CollageGrid images={featured} />
      <div className={s.buttonWrapper}>
        <Button href="/our-work">CHECK OUT WHAT WE'VE DONE</Button>
      </div>
    </Container>
  );
}
