import React from 'react';
import Headline from 'headline';
import Container from 'container';
import Button from 'button';
import s from './intro-section.module.scss';

export default function IntroSection() {
  return (
    <Container className={s.root}>
      <Headline h1 el="h3" className={s.title}>
        We are cxn
      </Headline>
      <Headline h2 el="p" reg dash center className={s.subtitle}>
        <span className={s.desktop}>Built to Build, We Better Your Brand</span>
        <span className={s.mobile}>
          A creative agency built to build better brands
        </span>
      </Headline>
      <p className={s.text}>
        We create user experiences that push businesses to succeed in today’s
        digital culture. Persistent, efficient, and transparent, we don’t just
        meet your goals, we exceed them.
      </p>
      <Button href="/about/">LEARN ABOUT US</Button>
    </Container>
  );
}
