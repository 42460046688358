import React from 'react';
import Button from 'button';
import SwiperBlock from 'shared/swiper-block';
import BlockList from 'shared/block-list';
import services from './data';

import s from './services.module.scss';

export default function Services() {
  const params = {
    slidesPerView: 3,
    spaceBetween: 0,
    noSwiping: true,
    breakpoints: {
      767: {
        slidesPerView: 1,
        spaceBetween: 0,
        noSwiping: false
      }
    }
  };

  return (
    <div className={s.services}>
      <div className="container">
        <SwiperBlock hoverEffect params={params}>
          {services.map(service => (
            <BlockList {...service} key={service.title} />
          ))}
        </SwiperBlock>
        <div className={s.buttonWrapper}>
          <Button href="/what-we-do/">But wait there's more!</Button>
        </div>
      </div>
    </div>
  );
}
