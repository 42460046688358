import React, { useEffect, useState } from 'react';
import Icon from 'icon';
import HeroWrapper from 'shared/hero-wrapper';
import useSetScrollRef from 'hooks/useSetScrollRef';

import s from './hero.module.scss';

export default function HomeHero() {
  const hero = useSetScrollRef();
  const [opacity, setOpacity] = useState(null);
  const handleScroll = () => {
    const scrollOffset = 0.25 * window.innerHeight;
    const opacity = 1 - window.scrollY / scrollOffset;
    setOpacity(opacity <= 0 ? 0 : opacity);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    return () =>
      window.removeEventListener('scroll', handleScroll) ||
      window.removeEventListener('resize', handleScroll);
  }, []);
  return (
    <HeroWrapper video="4f388e35b062132dc4955bc0673a3f8a" className={s.hero} img="cxn-hero-home" ref={hero}>
      <img
        src="/svg/cxn-creative-agency.svg"
        alt="cxn-creative-agency"
        className={s.img}
      />
      <div className={s.scrollText} style={{ opacity }}>
        <span>scroll</span>
        <Icon icon="long-arrow" className={s.arrow} />
      </div>
    </HeroWrapper>
  );
}
