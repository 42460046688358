import React, { useState, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import { navigate } from 'gatsby';
// import { Navigation } from 'swiper/dist/js/swiper.esm';

import s from './swiper-block.module.scss';

function Block({ children, hoverEffect, pointer, ...props }) {
  return (
    <div className="swiper-slide" {...props}>
      <div
        className={`${s.block} ${pointer ? s.pointer : ''} ${
          hoverEffect ? s.hoverEffect : ''
        } swiper-block`}
      >
        {children}
      </div>
    </div>
  );
}

export default function SwiperBlock({
  children,
  params,
  className,
  hoverEffect
}) {
  const [swiper, updateSwiper] = useState(null);
  const [ellipses, updateEllipses] = useState(null);
  const [activeSwiper, setActiveSwiper] = useState(0);

  const handleActive = ({ activeIndex }) => setActiveSwiper(activeIndex);
  const handleResize = ({ currentBreakpoint }) => {
    const { slidesPerView } = params.breakpoints[currentBreakpoint] || params;
    const ellipsesCount = children.length - (slidesPerView - 1);
    const newCount = ellipsesCount > 1 && Array(ellipsesCount).fill(null);
    updateEllipses(newCount);
  };

  useEffect(() => {
    if (swiper) {
      handleActive(swiper);
      handleResize(swiper);
      swiper.on('slideChange', () => handleActive(swiper));
      swiper.on('resize', () => handleResize(swiper));
    }
  }, [swiper]);

  const slideTo = idx => swiper && swiper.slideTo(idx);

  return (
    <div className={`${s.root} ${className || ''}`}>
      <div className={s.wrapper}>
        <Swiper {...params} getSwiper={updateSwiper}>
          {children.map((child, idx) => (
            <Block
              hoverEffect={hoverEffect || child.props.href}
              key={`child-${idx}`}
              onClick={
                child.props.href ? () => navigate(child.props.href) : null
              }
              pointer={child.props.href ? s.pointer : null}
            >
              {child}
            </Block>
          ))}
        </Swiper>
      </div>
      {ellipses && (
        <div className={s.ellipses}>
          {ellipses.map((_, i) => {
            const goToSlide = () => slideTo(i);
            const activeClass = activeSwiper === i ? s.active : '';
            return (
              <button
                key={`slide-button-${i}`}
                onClick={goToSlide}
                type="button"
                className={`${s.slideButton} ${activeClass}`}
              >
                <span />
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
}
