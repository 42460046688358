import React from 'react';
import Meta from 'meta';
import Hero from 'components/page-home/hero';
import IntroSection from 'components/page-home/intro-section';
import FeaturedWork from 'components/page-home/featured-work';
import ExperiencesServices from 'components/page-home/experiences-services';
import Testimonial from 'shared/testimonial';
import { testimonial } from 'utils/randomTestimonial';
import MapSection from 'components/page-home/map-section';
import ClientsSection from 'components/page-home/clients-section';
import LetsStart from 'shared/lets-start';

export default function Home({ pageContext, ...props }) {
  const { featuredList } = pageContext;
  return (
    <>
      <Meta
        title="CXN Collective | Digital Collective in Miami, DC, Latin America, and More"
        description="Mark your company’s place in the modern world with top digital marketing, design, and web development teams cementing your online presence."
        canonical="https://cxncollective.com/"
      />
      <Hero />
      <main>
        <IntroSection />
        <ExperiencesServices />
        <FeaturedWork {...{ featuredList }} />
        <Testimonial {...testimonial} />
        <MapSection />
        <ClientsSection />
        <LetsStart />
      </main>
    </>
  );
}
