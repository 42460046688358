/* eslint-disable react/jsx-one-expression-per-line  */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Headline from 'headline';
import Container from 'container';
import { Waypoint } from 'react-waypoint';
import Img from 'img';
import Icon from 'icon';
import Button from 'button';
import locations from './data';

import s from './map-section.module.scss';

const mapLoaded = { loaded: false };

function calcTime(offset) {
  const date = new Date();
  const utc = date.getTime() + date.getTimezoneOffset() * 60000;
  return new Date(utc + 3600000 * offset);
}

function Location({ title, cities, timeZone, ...props }) {
  const [time, setTime] = useState(null);
  const zone = title.split(' ').join('-');
  useEffect(() => {
    const date = calcTime(timeZone);
    const currentTime = {
      seconds: date.getSeconds(),
      minutes: date.getMinutes(),
      hours: date.getHours() % 12
    };
    setTime(currentTime);
  }, []);
  const secondAnimation = `seconds-${zone}`;
  const minuteAnimation = `minutes-${zone}`;
  const hourAnimation = `hours-${zone}`;
  return (
    <>
      {time && (
        <Helmet>
          <style>
            {`
          @keyframes ${secondAnimation} {
            from {
              transform: translate(0, -50%)
                rotate(calc(${time.seconds} * 6deg - 90deg));
            }
            to {
              transform: translate(0, -50%)
                rotate(calc(${time.seconds} * 6deg + 360deg - 90deg));
            }
          }
          @keyframes ${minuteAnimation} {
            from {
              transform: translate(0, -50%)
                rotate(calc(${time.minutes} * 6deg - 90deg));
            }
            to {
              transform: translate(0, -50%)
                rotate(calc(${time.minutes} * 6deg + 360deg));
            }
          }
          @keyframes ${hourAnimation} {
            from {
              transform: translate(0, -50%)
                rotate(calc(${time.hours} * 30deg - 90deg));
            }
            to {
              transform: translate(0, -50%)
                rotate(calc(${time.hours} * 30deg + 360deg - 90deg));
            }
          }
          .${secondAnimation} {
            animation: ${secondAnimation} 60s steps(60) infinite;
          }
            .${minuteAnimation} {
              animation: ${minuteAnimation} 3600s steps(60) infinite;
              animation-delay: calc(${time.seconds} * -1 * 1s);
            }
            .${hourAnimation} {
              animation: ${hourAnimation} calc(12 * 60 * 60s) linear infinite;
              animation-delay: calc(
                calc(${time.minutes} * -60 * 1s) +
                  calc(${time.seconds} * -1 * 1s)
              );
            }
          `}
          </style>
        </Helmet>
      )}
      <div className={s.location} {...props}>
        <div>
          <div className={s.clock}>
            <span className={s.center} />
            <span className={`${s.seconds} ${secondAnimation}`} />
            <span className={`${s.minutes} ${minuteAnimation}`} />
            <span className={`${s.hours} ${hourAnimation}`} />
            <img src="/svg/cxn-clock-background.svg" className={s.clockIcon} alt="clock" />
          </div>
        </div>
        <div className={s.content}>
          <p className={s.title}>{title}</p>
          <ul>
            {cities.map(city => (
              <li key={city} className={s.city}>
                {city}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default function MapSection() {
  const [src, setSrc] = useState(
    mapLoaded.loaded ? '/svg/map.svg' : '/images/cxn-map2x-small.png'
  );
  useEffect(() => {
    if (!mapLoaded.loaded) {
      const image = new Image();
      image.src = '/svg/map.svg';
      image.onload = () => {
        setSrc('/svg/map.svg');
        mapLoaded.loaded = true;
      };
    }
  }, []);
  return (
    <Container className={s.root}>
      <Headline h2 el="h3" className={s.mainTitle} dash>
        We speak your language
      </Headline>
      <Headline h1 el="h4" className={s.subtitle}>
        Let's work together
      </Headline>
      <img className={s.map} src={src} alt="map" />
      <div className={s.locations}>
        {locations.map(({ timeZone, ...props }) => (
          <Location timeZone={timeZone} {...props} key={props.title} />
        ))}
      </div>
      <div className={s.buttonWrapper}>
        <Button className={s.button} href="/contact">
          The clock's ticking
        </Button>
      </div>
    </Container>
  );
}
