import React from 'react';
import Img from 'img';
import Headline from 'headline';
import Button from 'button';
import Services from './services';

import s from './experiences-services.module.scss';

export default function ExperiencesServices() {
  return (
    <section className={s.root}>
      <div className={s.experiences}>
        <div className={s.imgWrapper}>
          <Img src="cxn-banner-12x" className={s.img} />
        </div>
        <div className="container">
          <div className={s.content}>
            <Headline h2 dash className={s.title} el="h3">
              Cut through the clutter
            </Headline>
            <Headline h1 className={s.subtitle} el="h4">
              MAKE YOUR DIGITAL MARK
            </Headline>
            <p className={s.text}>
              Distinguish your business from the rest. CXN solidifies your
              online presence and brand so you gain user trust and convert more.
              We make sure your site provides unmatched value for your target
              audience, making sure your business never goes out of style.
            </p>
            <Button className={s.button} href="/what-we-do/">
             SEE WHAT WE CAN DO
            </Button>
          </div>
        </div>
      </div>
      <Services />
    </section>
  );
}
