export default [
  {
    title: 'north america',
    cities: ['miami', 'south carolina', 'san francisco'],
    timeZone: '-4'
  },
  {
    title: 'latin america',
    cities: ['venezuela', 'bolivia'],
    timeZone: '-5'
  },
  {
    title: 'europe',
    cities: ['new zealand', 'germany'],
    timeZone: '+1'
  }
];
